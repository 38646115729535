<script setup lang="ts">
  import Infobox from '../../components/Widgets/Composite/Infobox.vue'
  import useTracking from '../../composables/Tracking'

  const infoData = computed(() => {
    return {
      body: useContentStore().content.content,
      display_hint: 'yellow',
      image: null,
      type: 'infobox',
      uuid: 'infobox',
    }
  })
  const nuxtApp = useNuxtApp()
  const { trackInteraction } = useTracking()
  onMounted(() => {
    nuxtApp.hooks.hook('interaction:event', (data: InteractionData) => {
      const { interaction } = data

      trackInteraction(
        interaction,
        'Authors Page',
        infoData?.title,
        infoData?.type
      )
    })
  })
</script>

<template>
  <main class="bp-main-grid mt-13">
    <div
      class="main-content bp-main-grid border-b-small border-primary-tone py-9"
    >
      <Infobox
        v-interaction="{
          uuid: infoData.uuid,
        }"
        class="center-content"
        :data="infoData"
      />
    </div>
    <slot />
  </main>
</template>
